import * as React from "react";
import {
    BooleanField,
    BooleanInput,
    Create,
    Datagrid,
    DateField,
    ShowButton,
    FunctionField,
    List,
    NumberInput,
    required,
    Show,
    SimpleForm,
    SimpleShowLayout,
    TextField,
    TextInput
} from 'react-admin';
import {AddProductAvailabilityButton} from "../component/button";

const filters = [
    <TextInput label="Search" source="q" size="small" alwaysOn/>,
    <NumberInput label="№" source="ids"/>
];

export const ProductAvailableList = () => (
    <List filters={filters}>
        <Datagrid>
            <TextField source="id" label="№ Товара"/>
            <FunctionField
                label="Изображение"
                render={record => {
                    return (
                        <img
                            height={80}
                            src={`/file?name=${record.s3file}`}
                        />
                    )
                }
                }/>
            <TextField source="name" label="Название"/>
            <TextField source="color" label="Цвет"/>
            <TextField source="price" label="Цена за 1 шт."/>
            <FunctionField
                label="Количество"
                render={record => {
                    return (
                        <AddProductAvailabilityButton {...{product: record.id, availability: record.availability}}/>
                    )
                }
                }/>
            {/*<ShowButton/>*/}
        </Datagrid>
    </List>
);

export const ProductAvailableCreate = () => (
    <Create>
        <SimpleForm>
            <NumberInput label="Номер товара" source="productId" validate={[required()]}/>
            <NumberInput label="Количество" source="count" validate={[required()]}/>
            <BooleanInput label="Доступно" source="available" validate={[required()]} options={{}}/>
        </SimpleForm>
    </Create>
);

export const ProductAvailableShow = () => (
    <Show>
        <SimpleShowLayout>
            <TextField source="id" label="№ Товара"/>
            <FunctionField
                label="Изображение"
                render={record => {
                    return (
                        <img
                            height={80}
                            src={`/file?name=${record.s3file}`}
                        />
                    )
                }
                }/>
            <TextField source="name" label="Название"/>
            <TextField source="availability.count" label="Количество"/>
        </SimpleShowLayout>
    </Show>
);