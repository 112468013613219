import {fetchUtils} from "react-admin";
import simpleRestProvider from 'ra-data-simple-rest';

const fetchJson = (url, options = {}) => {
    if (!options.headers) {
        options.headers = new Headers({Accept: 'application/json'});
    }
    // add your own headers here
    if (localStorage.getItem('token')) {
        options.headers.set('Authorization', `Bearer ${localStorage.getItem('token')}`);
    }
    return fetchUtils.fetchJson(url, options);
};

const servicesHost = '/api/v1/react-admin';

const dataProvider = simpleRestProvider(servicesHost, fetchJson);

const myDataProvider = {
    ...dataProvider,
    create: (resource, params) => {
        if (resource !== 'product' && resource !== 'category') {
            // fallback to the default implementation
            return dataProvider.create(resource, params);
        }

        let formData = new FormData();
        formData.append('name', params.data.name);
        formData.append('description', params.data.description);
        formData.append('image', params.data.image.rawFile);

        if (resource === 'product') {
            formData.append('color', params.data.color);
            formData.append('price', params.data.price);
            params.data.gallery.forEach(img => formData.append('gallery', img.rawFile));
        }

        return fetchJson(`${servicesHost}/${resource}`, {
            method: 'POST',
            body: formData,
        })
            .then(
                ({headers, json}) => {
                    return {
                        data: json,
                        total: json.length,  // Теперь больше не требуется специальный заголовок, совместимый с CSE-Connect.
                    };
                }, ({reason}) => {
                    return Promise.reject(reason);
                })
            .catch((e) => {
                console.log(e);
                return Promise.reject(e);
            });
    },
    update: (resource, params) => {
        console.log("Update " + resource);
        if (resource !== 'product' && resource !== 'category') {
            // fallback to the default implementation
            return dataProvider.update(resource, params);
        }

        console.log("Update data: " + JSON.stringify(params));
        let formData = new FormData();
        formData.append('name', params.data.name);
        formData.append('description', params.data.description);

        if (typeof params.data.archived != "undefined") {
            console.log("archived: " + params.data.archived);
            formData.append('archived', params.data.archived);
        }

        if (resource === 'product') {
            formData.append('color', params.data.color);
            formData.append('price', params.data.price);
        }

        return fetchJson(`${servicesHost}/${resource}/${params.data.id}`, {
            method: 'POST',
            body: formData,
        })
            .then(
                ({headers, json}) => {
                    return {
                        data: json,
                        total: json.length,  // Теперь больше не требуется специальный заголовок, совместимый с CSE-Connect.
                    };
                }, ({reason}) => {
                    return Promise.reject(reason);
                })
            .catch((e) => {
                console.log(e);
                return Promise.reject(e);
            });
    }
};

export default myDataProvider;