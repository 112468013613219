import * as React from "react";
import {
    Create,
    Datagrid,
    DateField,
    EditButton,
    FunctionField, ImageField, ImageInput,
    List, NumberInput, required,
    SimpleForm,
    TextField,
    TextInput
} from 'react-admin';

export const CategoryList = () => (
    <List>
        <Datagrid>
            <TextField source="id" label="#"/>
            <FunctionField
                label="Изображение"
                render={record => {
                    return (
                        <img
                            height={80}
                            src={`/file?name=${record.s3file}`}
                        />
                    )
                }
                }/>
            <TextField source="name" label="Название"/>
            <DateField source="created" label="Создано"/>
            <DateField source="modified" label="Изменено"/>
            <DateField source="archived" label="Архив"/>
            <EditButton />
        </Datagrid>
    </List>
);

export const CategoryCreate = () => (
    <Create>
        <SimpleForm>
            <TextInput label="Название" source="name" validate={[required()]} fullWidth multiline={true} />
            <TextInput label="Описание" source="description" validate={[required()]} fullWidth multiline={true} />
            <ImageInput source="image" label="Изображение" placeholder={<p>Перетащите файл в область загрузки</p>}
                        validate={required()}>
                <ImageField source="src" title="title"/>
            </ImageInput>
        </SimpleForm>
    </Create>
);