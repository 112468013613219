import * as React from "react";
import {Datagrid, DateField, EditButton, List, TextField} from 'react-admin';

export const DiscountList = () => (
    <List>
        <Datagrid>
            <TextField source="id" label="#"/>
            <TextField source="name" label="Название"/>
            <TextField source="description" label="Описание"/>
            <TextField source="percent" label="Размер, %"/>
            <DateField source="created" label="Создано"/>
            <DateField source="modified" label="Изменено"/>
            <DateField source="archived" label="Архив"/>
            <EditButton />
        </Datagrid>
    </List>
);