import * as React from "react";
import {Edit, required, SimpleForm, TextInput} from 'react-admin';
import {RichTextInput} from 'ra-input-rich-text';

export const StaticResourceEdit = () => (
    <Edit>
        <SimpleForm>
            <TextInput disabled label="Id" source="id" />
            <TextInput label="Ссылка" source="ref" validate={required()} fullWidth={true} />
            <RichTextInput label="Контент" source="content" />
        </SimpleForm>
    </Edit>
);