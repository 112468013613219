import * as React from "react";
import {
    BooleanInput,
    Datagrid,
    DateField,
    EditButton,
    FunctionField,
    List,
    ListButton, NumberInput,
    Show,
    SimpleShowLayout,
    TextField,
    TextInput,
    Toolbar
} from 'react-admin';

const filters = [
    <TextInput label="Search" source="q" size="small" alwaysOn/>,
    <BooleanInput label="Архивные" source="archive" alwaysOn/>,
    <NumberInput label="Номер товара" source="product"/>
];

export const CartPositionList = () => (
    <List filters={filters}>
        <Datagrid rowClick="show">
            <TextField source="id" label="#"/>
            <FunctionField
                label="Изображение"
                render={record => {
                    return (
                        <img
                            height={80}
                            src={`/file?name=${record.catalogEntry.product.s3file}`}
                        />
                    )
                }
                }/>
            <TextField source="catalogEntry.product.name" label="Товар"/>
            <TextField source="catalogEntry.category.name" label="Категория"/>
            <TextField source="count" label="Количество"/>
            <TextField source="discount.name" label="Скидка"/>
            <DateField source="created" label="Создано"/>
            <DateField source="modified" label="Изменено"/>
            <DateField source="archived" label="Архив"/>
            <EditButton />
        </Datagrid>
    </List>
);

const ListActions = () => (
    <Toolbar>
        <EditButton/>
        {/*<DeleteWithConfirmButton*/}
        {/*    confirmContent="Архивировать позицию"*/}
        {/*/>*/}
        <ListButton />
    </Toolbar>
);

export const CartPositionShow = () => (
    <Show>
        <SimpleShowLayout>
            <TextField source="id" label="#"/>
            <TextField source="catalogEntry.product.name" label="Название товара"/>
            <TextField source="catalogEntry.category.name" label="Категория"/>
            <TextField source="discount.name" label="Скидка"/>
            <TextField source="count" label="Количество"/>
            <DateField source="created" label="Создан"/>
            <DateField source="modified" label="Изменен"/>
            <DateField source="archived" label="Архив"/>
        </SimpleShowLayout>
    </Show>
);