import decodeJwt from 'jwt-decode';

const schedule = require('node-schedule');

const authProvider = {
    login: ({username, password}) => {
        const request = new Request('/auth/realms/cp/protocol/openid-connect/token', {
            method: 'POST',
            body: new URLSearchParams({username, password, client_id: "backend-app", grant_type: "password"}),
            headers: new Headers({'Content-Type': 'application/x-www-form-urlencoded'}),
        });
        return fetch(request)
            .then(response => {
                if (response.status < 200 || response.status >= 300) {
                    throw new Error(response.statusText);
                }
                return response.json();
            })
            .then((json) => {
                const decodedToken = decodeJwt(json.access_token);
                console.log('json: ' + JSON.stringify(json))
                console.log('decodedToken: ' + JSON.stringify(decodedToken))
                localStorage.setItem('token', json.access_token);
                localStorage.setItem('expires', new Date().getTime() + (1000 * json.expires_in));
                localStorage.setItem("token-type", json.token_type);
                localStorage.setItem("refresh-token", json.refresh_token);
                localStorage.setItem('permissions', decodedToken.resource_access['backend-app'].roles);

                schedule.gracefulShutdown();

                const job = schedule.scheduleJob('*/4 * * * *', function(){
                    console.log('Refresh token...');
                    const refreshToken = localStorage.getItem('refresh-token');
                    const refresh = new Request('/auth/realms/cp/protocol/openid-connect/token', {
                        method: 'POST',
                        body: new URLSearchParams({refresh_token: refreshToken, client_id: "backend-app", grant_type: "refresh_token"}),
                        headers: new Headers({'Content-Type': 'application/x-www-form-urlencoded'}),
                    });
                    fetch(refresh)
                        .then(response => {
                            if (response.status < 200 || response.status >= 300) {
                            }
                            return response.json();
                        })
                        .then((json) => {
                            const decodedToken = decodeJwt(json.access_token);
                            console.log('json: ' + JSON.stringify(json))
                            console.log('decodedToken: ' + JSON.stringify(decodedToken))
                            localStorage.setItem('token', json.access_token);
                            localStorage.setItem('expires', new Date().getTime() + (1000 * json.expires_in));
                            localStorage.setItem("token-type", json.token_type);
                            localStorage.setItem("refresh-token", json.refresh_token);
                            localStorage.setItem('permissions', decodedToken.resource_access['backend-app'].roles);
                        });
                });
            });
    },
    checkError: (error) => { /* ... */
    },
    checkAuth: () => {
        let valid = false;

        const expires = localStorage.getItem('expires');

        if (expires) {
            valid = Number(expires) > new Date().getTime();
        }

        return localStorage.getItem('token') && valid ? Promise.resolve() : Promise.reject();
    },
    logout: () => {
        schedule.gracefulShutdown();

        localStorage.removeItem('token');
        localStorage.removeItem('token-type');
        localStorage.removeItem('permissions');
        localStorage.removeItem('refresh-token');
        return Promise.resolve();
    },
    getIdentity: () => { /* ... */
    },
    getPermissions: () => {
        let hasRole = false;
        const roles = localStorage.getItem('permissions');

        if (roles) {
            console.log("roles: ", roles)
            hasRole = (roles.indexOf("ADMIN")) !== -1
        }

        console.log("getPermissions: ", hasRole)

        return roles ? Promise.resolve(hasRole) : Promise.reject();
    }
};

export default authProvider;