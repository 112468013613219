import * as React from "react";
import {
    ArrayField,
    BooleanInput,
    Create,
    Datagrid,
    DateField,
    EditButton,
    ShowButton,
    FunctionField,
    ImageField,
    ImageInput,
    List,
    NumberInput,
    required,
    Show,
    SimpleForm,
    SimpleShowLayout,
    SingleFieldList,
    TextField,
    TextInput
} from 'react-admin';
import {Box, Stack} from "@mui/material";
import {Item} from "../component/item";

import {styled} from '@mui/material/styles';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Collapse from '@mui/material/Collapse';
import Avatar from '@mui/material/Avatar';
import IconButton, {IconButtonProps} from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import {purple} from '@mui/material/colors';
import DeleteForever from '@mui/icons-material/DeleteForever';
import ShareIcon from '@mui/icons-material/Share';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import {AddProductAvailabilityButton, CreateCartPositionButton} from "../component/button";

const filters = [
    <TextInput label="Search" source="q" size="small" alwaysOn/>,
    <BooleanInput label="Архивные" source="archive" alwaysOn/>,
    <NumberInput label="№" source="ids"/>
];

export const ProductList = () => (
    <List filters={filters}>
        <Datagrid>
            <TextField source="id" label="#"/>
            <FunctionField
                label="Изображение"
                render={record => {
                    return (
                        <img
                            height={80}
                            src={`/file?name=${record.s3file}`}
                        />
                    )
                }
                }/>
            <TextField source="name" label="Название"/>
            <DateField source="created" label="Создано"/>
            <DateField source="modified" label="Изменено"/>
            <TextField source="color" label="Цвет"/>
            <TextField source="price" label="Цена"/>

            <FunctionField
                label="Доступно на складе"
                render={record => {
                    return (
                        <AddProductAvailabilityButton {...{product: record.id, availability: record.availability}}/>
                    )
                }
                }/>

            <DateField source="archived" label="Архив"/>
            <ShowButton/>
            <EditButton/>
        </Datagrid>
    </List>
);

export const ProductCreate = () => (
    <Create>
        <SimpleForm>
            <TextInput label="Название" source="name" validate={[required()]} fullWidth/>
            <TextInput label="Описание" source="description" validate={[required()]} fullWidth={true} multiline={true}/>
            <TextInput label="Цвет" source="color" validate={[required()]}/>
            <NumberInput label="Цена" source="price" validate={[required()]}/>
            <ImageInput source="image" label="Изображение" placeholder={<p>Перетащите файл в область загрузки</p>}
                        validate={required()}>
                <ImageField source="src" title="title"/>
            </ImageInput>
            <ImageInput source="gallery" label="Галерея" placeholder={<p>Перетащите файлы в область загрузки</p>}
                        multiple={true} validate={required()}>
                <ImageField source="src" title="title"/>
            </ImageInput>
        </SimpleForm>
    </Create>
);

const ExpandMore = styled((props) => {
    const {expand, ...other} = props;
    return <IconButton {...other} />;
})(({theme, expand}) => ({
    transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shortest,
    }),
}));

export const ProductShow = () => (
    <Show>
        <SimpleShowLayout>
            <FunctionField
                label="Изображение"
                render={record => {
                    return (
                        <img
                            height={160}
                            src={`/file?name=${record.s3file}`}
                        />
                    )
                }
                }/>
            <TextField source="id" label="#"/>
            <TextField source="name" label="Название"/>
            <TextField source="description" label="Описание"/>
            <DateField source="created" label="Создан"/>
            <DateField source="modified" label="Изменен"/>
            <TextField source="s3folder" label="Галерея"/>
        </SimpleShowLayout>

        <SimpleShowLayout>
            <Box sx={{width: '100%'}}>
                <Stack spacing={2} sx={{padding: 2}} direction="row" justifyContent="left" alignItems="left">
                    <ArrayField source="gallery" label="Галерея">
                        <SingleFieldList linkType={false}>
                            <FunctionField
                                render={record => {

                                    // https://mui.com/material-ui/react-card/

                                    return (
                                        <Card sx={{maxWidth: 345, margin: 1}}>
                                            <CardHeader
                                                avatar={
                                                    <Avatar sx={{bgcolor: purple[500]}} aria-label="recipe">
                                                        PS
                                                    </Avatar>
                                                }
                                                action={
                                                    <IconButton aria-label="settings">
                                                        <MoreVertIcon/>
                                                    </IconButton>
                                                }
                                                title="Фото"
                                                subheader={record.substr(record.lastIndexOf("/") + 1)}
                                            />
                                            <CardMedia
                                                component="img"
                                                height="160"
                                                image={`/file?name=${record}`}
                                                alt={record}
                                            />
                                            <CardContent>
                                                <Typography variant="body2" color="text.secondary">

                                                </Typography>
                                            </CardContent>
                                            <CardActions disableSpacing>
                                                <IconButton aria-label="Удалить" onClick={() => {alert('Уже чиним')}}>
                                                    <DeleteForever/>
                                                </IconButton>
                                                <IconButton aria-label="Поделиться" onClick={() => {alert('Уже чиним')}}>
                                                    <ShareIcon/>
                                                </IconButton>
                                            </CardActions>
                                        </Card>
                                    )
                                }
                                }/>
                        </SingleFieldList>
                    </ArrayField>
                </Stack>
            </Box>
        </SimpleShowLayout>
    </Show>
);