import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import AddCircle from "@mui/icons-material/AddCircle";
import {useDataProvider, useGetList, useRecordContext, useRefresh} from "react-admin";

export const ProductAddToCategoryButton = () => {

    const record = useRecordContext();

    const refresh = useRefresh();

    const dataProvider = useDataProvider();

    const categoryList = useGetList(
        'category',
        {
            pagination: {page: 1, perPage: 1000},
            sort: {field: 'name', order: 'ASC'}
        }
    );
    const discountList = useGetList(
        'discount',
        {
            pagination: {page: 1, perPage: 1000},
            sort: {field: 'name', order: 'ASC'}
        }
    );

    const [open, setOpen] = React.useState(false);
    const [category, setCategory] = React.useState(null);
    const [discount, setDiscount] = React.useState(null);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleClick = () => {
        console.log("create: ", record.id, category, discount);
        dataProvider.create('catalog-entry', {data: {product: record.id, discount, category}})
            .then(response => {
                console.log(response);
                refresh();
            });
        setOpen(false);
    };

    return (
        <div>
            <Button variant="outlined" color="success" onClick={handleClickOpen}>
                <AddCircle/>
            </Button>
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>Размещение в каталоге</DialogTitle>
                <DialogContent>
                    <TextField
                        margin="dense"
                        id="product-id"
                        label="Номер товара"
                        type="text"
                        value={record.id}
                        variant="standard"
                        disabled={true}
                        fullWidth
                    />
                    <TextField
                        autoFocus
                        id="select-category-id"
                        select
                        label="Категория каталога"
                        onChange={e => setCategory(e.target.value)}
                        SelectProps={{
                            native: true,
                        }}
                        helperText="Выберите категорию"
                        variant="standard"
                        required={true}
                        fullWidth
                    >
                        <option/>
                        {!!categoryList.data && categoryList.data.map((option) => (
                            <option key={option.id} value={option.id}>
                                {option.name}
                            </option>
                        ))}
                    </TextField>
                    <TextField
                        id="select-discount-id"
                        select
                        label="Скидка"
                        onChange={e => setDiscount(e.target.value)}
                        SelectProps={{
                            native: true,
                        }}
                        helperText="Выберите скидку"
                        variant="standard"
                        required={true}
                        fullWidth
                    >
                        <option/>
                        {!!discountList.data && discountList.data.map((option) => (
                            <option key={option.id} value={option.id}>
                                {option.name}
                            </option>
                        ))}
                    </TextField>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Отменить</Button>
                    <Button onClick={handleClick}>Разместить</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};

export const CreateCartPositionButton = (props) => {

    const record = useRecordContext();

    const refresh = useRefresh();

    const dataProvider = useDataProvider();

    const discountList = useGetList(
        'discount',
        {
            pagination: {page: 1, perPage: 1000},
            sort: {field: 'name', order: 'ASC'}
        }
    );

    const [open, setOpen] = React.useState(false);
    const [discount, setDiscount] = React.useState(null);
    const [count, setCount] = React.useState(0);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleClick = () => {
        dataProvider.create('cart-position', {data: {catalogEntry: props.entry, discount, count}})
            .then(response => {
                console.log(response);
                refresh();
            });
        setOpen(false);
    };

    return (
        <div>
            <Button variant="outlined" color="secondary" onClick={handleClickOpen}>
                <AddCircle/>
            </Button>
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>Добавление позиции для покупки</DialogTitle>
                <DialogContent>
                    <TextField
                        margin="dense"
                        id="catalog-entry-id"
                        label="Номер в каталоге"
                        type="text"
                        value={props.entry}
                        variant="standard"
                        disabled={true}
                        fullWidth
                    />
                    <TextField
                        id="select-discount-id"
                        select
                        label="Скидка"
                        onChange={e => setDiscount(e.target.value)}
                        SelectProps={{
                            native: true,
                        }}
                        helperText="Выберите скидку"
                        variant="standard"
                        required={true}
                        fullWidth
                    >
                        <option/>
                        {!!discountList.data && discountList.data.map((option) => (
                            <option key={option.id} value={option.id}>
                                {option.name}
                            </option>
                        ))}
                    </TextField>
                    <TextField
                        margin="dense"
                        id="count"
                        label="Количество"
                        type="number"
                        variant="standard"
                        required={true}
                        onChange={e => setCount(e.target.value)}
                        fullWidth
                    />

                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Отменить</Button>
                    <Button onClick={handleClick}>Создать</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};

export const AddProductAvailabilityButton = (props) => {

    const dataProvider = useDataProvider();

    const refresh = useRefresh();

    const [open, setOpen] = React.useState(false);
    const [count, setCount] = React.useState(0);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleClick = () => {
        dataProvider.create('available', {data: {productId: props.product, available: count > 0, count}})
            .then(response => {
                console.log(response);
                refresh();
            });
        setOpen(false);
    };

    return (
        <div>
            <Button variant="outlined" color="secondary" onClick={handleClickOpen}>
                {!!props.availability && !!props.availability.available ? <span><b>{props.availability.count}</b></span> : <AddCircle/>}
            </Button>
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>Количество товара</DialogTitle>
                <DialogContent>
                    <TextField
                        margin="dense"
                        id="product-id"
                        label="Номер товара"
                        type="text"
                        value={props.product}
                        variant="standard"
                        disabled={true}
                        fullWidth
                    />
                    <TextField
                        margin="dense"
                        id="count"
                        label="Количество"
                        type="number"
                        variant="standard"
                        required={true}
                        onChange={e => setCount(e.target.value)}
                        fullWidth
                    />

                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Отменить</Button>
                    <Button onClick={handleClick}>Сохранить</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};