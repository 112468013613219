import * as React from "react";
import {
    BooleanInput, Datagrid,
    DateInput,
    Edit, FunctionField,
    ImageField,
    ImageInput,
    NumberInput,
    required,
    SimpleForm, TextField,
    TextInput
} from 'react-admin';

export const ProductAvailabilityEdit = () => (
    <Edit>
        <SimpleForm>
            <FunctionField
                render={record => {
                    return (
                        <img
                            height={80}
                            src={`/file?name=${record.product.s3file}`}
                        />
                    )
                }
                }/>

            <FunctionField
                render={record => {
                    return (
                        <p> {`#${record.product.id} - ${record.product.name}`} </p>
                    )
                }
                }/>

            <TextInput disabled label="#" source="id" />
            <NumberInput label="Номер товара" source="product.id" validate={[required()]}/>
            <NumberInput label="Количество" source="count" validate={[required()]}/>
            <BooleanInput label="Доступно" source="available" validate={[required()]} options={{}}/>
        </SimpleForm>
    </Edit>
);