import * as React from "react";
import {DateInput, Edit, NumberInput, required, SimpleForm, TextInput} from 'react-admin';

export const DiscountEdit = () => (
    <Edit>
        <SimpleForm>
            <TextInput disabled label="Id" source="id" />
            <TextInput label="Название" source="name" validate={required()} multiline={true} fullWidth={true} />
            <TextInput label="Описание" source="description" validate={required()} multiline={true} fullWidth={true} />
            <NumberInput label="Размер скидки, %" source="percent" validate={required()} />
            <DateInput label="Архивация" source="archived" />
        </SimpleForm>
    </Edit>
);