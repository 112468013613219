import * as React from "react";
import {
    BooleanInput,
    Datagrid,
    DateField,
    FunctionField,
    List,
    TextField,
    TextInput,
    useRecordContext
} from 'react-admin';
import {Box, Stack} from '@mui/material';
import AddCircle from '@mui/icons-material/AddCircle';
import {Item} from "../component/item";
import {CreateCartPositionButton, ProductAddToCategoryButton} from "../component/button";
import Button from "@mui/material/Button";

const filters = [
    <TextInput label="Search" source="q" size="small" alwaysOn/>,
    <BooleanInput label="Архивные" source="archive" alwaysOn/>
];

const ProductPanel = () => {
    const record = useRecordContext();
    return (
        <Box sx={{width: '100%'}}>
            <Stack spacing={2} sx={{padding: 2}} direction="row" justifyContent="left" alignItems="left">
                {record.catalogEntries.map(entry => (


                    <Item>
                        <p>Категория: {entry.category.name}</p>
                        <ul>{entry.cartPositions.map(position => (
                            <li>
                                <span>{position.count}</span>
                                <span>шт = </span>
                                <span>{position.sum}</span>
                                <span>руб</span>
                                <span> (id:</span>
                                <span><a href={`#/cart-position/${position.id}/show`}>{position.id}</a></span>
                                <span>)</span>
                            </li>
                        ))}</ul>

                        <p>
                            <CreateCartPositionButton {...{entry: entry.id}}/>
                        </p>

                    </Item>
                ))}
                <Item>
                    <ProductAddToCategoryButton/>
                </Item>

            </Stack>
        </Box>
    );
};

export const ProductTreeList = () => (
    <List filters={filters}>
        <Datagrid expand={ProductPanel} rowClick="show">
            <TextField source="id" label="#"/>
            <FunctionField
                label="Изображение" render={record => {
                return (
                    <img
                        height={80}
                        src={`/file?name=${record.s3file}`}
                    />
                )
            }
            }
            />
            <TextField source="name" label="Название"/>
            <FunctionField
                label="В каталоге"
                render={record => record.catalogEntries.length}/>
            <FunctionField
                label="Варианты покупки"
                render={record => record.catalogEntries.reduce(function (accumulator, currentValue) {
                    return accumulator + currentValue.cartPositions.length;
                }, 0)}/>
            <DateField source="created" label="Создано"/>
            <DateField source="modified" label="Изменено"/>
            <TextField source="color" label="Цвет"/>
            <TextField source="price" label="Цена"/>
            <TextField source="stockAvailability" label="Доступно на складе"/>
            <DateField source="archived" label="Архив"/>
        </Datagrid>
    </List>
);