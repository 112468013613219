import * as React from "react";
import {DateInput, Edit, ImageField, ImageInput, NumberInput, required, SimpleForm, TextInput} from 'react-admin';

export const ProductEdit = () => (
    <Edit>
        <SimpleForm>
            <TextInput disabled label="Id" source="id" />
            <TextInput label="Название" source="name" validate={required()} multiline={true} fullWidth={true} />
            <TextInput label="Описание" source="description" validate={required()} multiline={true} fullWidth={true} />
            <TextInput label="Цвет" source="color" validate={required()} />
            <NumberInput label="Цена" source="price" validate={required()} />
            <DateInput label="Архивация" source="archived" />
            {/*<ImageInput source="image" label="Изображение" placeholder={<p>Перетащите файл в область загрузки</p>}>*/}
            {/*    <ImageField source="src" title="title" />*/}
            {/*</ImageInput>*/}
            {/*<ImageInput source="gallery" label="Добавить фото в галерею" placeholder={<p>Перетащите файлы в область загрузки</p>}*/}
            {/*            multiple={true}>*/}
            {/*    <ImageField source="src" title="title"/>*/}
            {/*</ImageInput>*/}
        </SimpleForm>
    </Edit>
);