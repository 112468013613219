import * as React from "react";
import {Edit, required, SelectInput, SimpleForm, TextInput} from 'react-admin';

export const OrderEdit = (props) => {
    const transform = data => ({
        id: data.id,
        receiptUrl: data.receiptUrl,
        trackNumber: data.trackNumber,
        status: data.status.name
    });
    return (
        <Edit {...props} transform={transform}>
            <SimpleForm>
                <TextInput disabled label="Номер" source="id"/>
                <SelectInput label="Статус" source="status.name" choices={[
                    {id: 'NEW', name: 'Новый заказ'},
                    {id: 'AWAITING_PAYMENT', name: 'Ожидает оплаты'},
                    {id: 'PAID', name: 'Оплачен'},
                    {id: 'AWAITING_SHIPPED', name: 'Ожидает отправки'},
                    {id: 'SHIPPED', name: 'Отправлен'},
                    {id: 'DELIVERED', name: 'Доставлен'},
                    {id: 'COMPLETED', name: 'Завершен'},
                    {id: 'CANCELED', name: 'Отменён'},
                ]} validate={required()}/>
                <TextInput label="Ссылка на чек" source="receiptUrl"/>
                <TextInput label="Трек-номер" source="trackNumber"/>
            </SimpleForm>
        </Edit>
    );
}