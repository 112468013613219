import * as React from "react";
import {Admin, Resource} from 'react-admin';
import {CategoryCreate, CategoryList} from './resource/category';
import {ProductCreate, ProductList, ProductShow} from './resource/product';
import authProvider from './authProvider';
import {ProductEdit} from "./edit/edit-product";
import {CategoryEdit} from "./edit/edit-category";
import {DiscountList} from "./resource/discount";
import {DiscountEdit} from "./edit/edit-discount";
import {CatalogEntryCreate, CatalogEntryList} from "./resource/catalog-entry";
import {StaticResourceList} from "./resource/static-resource";
import {StaticResourceEdit} from "./edit/edit-static-resource";
import Shop from '@mui/icons-material/Shop';
import Category from '@mui/icons-material/Category';
import Percent from '@mui/icons-material/Percent';
import AccountTree from '@mui/icons-material/AccountTree';
import PermDataSetting from '@mui/icons-material/PermDataSetting';
import Web from '@mui/icons-material/Web';
import ShoppingCart from '@mui/icons-material/ShoppingCart';
import AddShoppingCart from '@mui/icons-material/AddShoppingCart';
import SignalCellularAlt  from '@mui/icons-material/SignalCellularAlt';
import {OrderList, OrderShow} from "./resource/order";
import {OrderEdit} from "./edit/edit-order";
import {CartPositionList, CartPositionShow} from "./resource/cart-position";
import {ProductTreeList} from "./resource/product-tree";
import myDataProvider from "./data-provider/data-providers";
import {CartPositionEdit} from "./edit/edit-cart-position";
import {ProductAvailableCreate, ProductAvailableList, ProductAvailableShow} from "./resource/available";
import {ProductAvailabilityEdit} from "./edit/edit-available";

const App = () => (
    <Admin dataProvider={myDataProvider} authProvider={authProvider} disableTelemetry>
        <Resource options={{ label: 'Дерево товаров' }} name="product-tree" list={ProductTreeList} show={ProductShow} icon={AccountTree}/>
        <Resource options={{ label: 'Товары' }} name="product" list={ProductList} edit={ProductEdit} show={ProductShow} create={ProductCreate} icon={PermDataSetting}/>
        <Resource options={{ label: 'Склад' }} name="available" list={ProductAvailableList} edit={ProductAvailabilityEdit} show={ProductAvailableShow} create={ProductAvailableCreate} icon={SignalCellularAlt }/>
        <Resource options={{ label: 'Каталог' }} name="catalog-entry" list={CatalogEntryList} create={CatalogEntryCreate} icon={Shop} />
        <Resource options={{ label: 'Позиции' }} name="cart-position" list={CartPositionList} edit={CartPositionEdit} show={CartPositionShow} icon={AddShoppingCart} />
        <Resource options={{ label: 'Заказы' }} name="order" list={OrderList} edit={OrderEdit} show={OrderShow} icon={ShoppingCart} />
        <Resource options={{ label: 'Категории' }} name="category" list={CategoryList} edit={CategoryEdit} create={CategoryCreate} icon={Category}/>
        <Resource options={{ label: 'Скидки' }} name="discount" list={DiscountList} edit={DiscountEdit} icon={Percent}/>
        <Resource options={{ label: 'Страницы' }} name="static-resource" list={StaticResourceList} edit={StaticResourceEdit} icon={Web}/>
    </Admin>
);

export default App;