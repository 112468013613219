import * as React from "react";
import {DateInput, Edit, NumberInput, required, SimpleForm, TextInput} from 'react-admin';

export const CartPositionEdit = () => (
    <Edit>
        <SimpleForm>
            <TextInput disabled label="Id" source="id" />
            <NumberInput label="Количество" source="count" validate={required()} />
            <DateInput label="Архивация" source="archived" />
        </SimpleForm>
    </Edit>
);