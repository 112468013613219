import * as React from "react";
import {
    BooleanInput,
    Create,
    Datagrid,
    DateField,
    FunctionField, ImageField, ImageInput,
    List, NumberInput, required,
    SimpleForm,
    TextField,
    TextInput
} from 'react-admin';

const filters = [
    <TextInput label="Search" source="q" size="small" alwaysOn />,
    <BooleanInput label="Архивные" source="archive"  alwaysOn/>,
    <NumberInput label="Номер товара" source="product"/>
    ];

export const CatalogEntryList = () => (
    <List filters={filters}>
        <Datagrid>
            <TextField source="id" label="#"/>
            <FunctionField
                label="Изображение"
                render={record => {
                    return (
                        <img
                            height={80}
                            src={`/file?name=${record.product.s3file}`}
                        />
                    )
                }
                }/>
            <TextField source="category.name" label="Категория"/>
            <TextField source="product.id" label="Номер товара"/>
            <TextField source="product.name" label="Название товара"/>
            <DateField source="created" label="Создано"/>
            <DateField source="modified" label="Изменено"/>
            <DateField source="archived" label="Архив"/>
        </Datagrid>
    </List>
);

export const CatalogEntryCreate = () => (
    <Create>
        <SimpleForm>
            <NumberInput label="Товар" source="product" validate={[required()]}/>
            <NumberInput label="Категория" source="category" validate={[required()]}/>
            <NumberInput label="Скидка" source="discount" validate={[required()]}/>
        </SimpleForm>
    </Create>
);
