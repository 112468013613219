import * as React from "react";
import {
    ArrayField,
    Datagrid,
    DateField,
    EditButton,
    FunctionField,
    List,
    NumberField,
    ReferenceField,
    Show,
    SimpleShowLayout,
    TextField,
    UrlField
} from 'react-admin';

export const OrderList = () => (
    <List sort={{ field: 'id', order: 'DESC' }}>
        <Datagrid rowClick="show">
            <TextField source="id" label="#"/>
            <TextField source="status" label="Статус"/>
            <TextField source="createdby" label="e-mail"/>
            <DateField source="created" label="Создано"/>
            <DateField source="modified" label="Изменено"/>
            <EditButton/>
            {/*<ShowButton/>*/}
        </Datagrid>
    </List>
);

const OrderDetails = () => {
    return <h3>Детали заказа</h3>;
};
const OrderItems = () => {
    return <h3>Состав заказа</h3>;
};
const OrderAddress = () => {
    return <h3>Адрес доставки</h3>;
};

export const OrderShow = () => (
    <Show>
        <SimpleShowLayout>
            <OrderDetails/>
            <TextField source="id" label="Номер заказа"/>
            <TextField source="status.description" label="Статус"/>
            <DateField source="created" label="Создан"/>
            <DateField source="modified" label="Изменен"/>
            <UrlField source="receiptUrl" label="Ссылка на чек"/>
            <TextField source="trackNumber" label="Трек-номер"/>
            <TextField source="orderInfo.sum" label="Сумма заказа"/>
            <TextField source="orderInfo.deliveryPrice" label="Стоимость доставки"/>
        </SimpleShowLayout>

        <SimpleShowLayout>
            <OrderItems/>
            <ArrayField source="orderInfo.positions" label="Позиции">
                <Datagrid bulkActionButtons={false} size={'small'}>
                    <FunctionField
                        label="Изображение"
                        render={record => {
                            return (
                                <img
                                    height={80}
                                    src={`/file?name=${record.catalogEntry.product.s3file}`}
                                />
                            )
                        }
                        }/>

                    <ReferenceField label="Купленная позиция" source="id" reference="cart-position" link="show">
                        <TextField source="catalogEntry.product.name" />
                    </ReferenceField>
                    <ReferenceField label="Номер товара" source="catalogEntry.product.id" reference="product" link="show">
                        <TextField source="id" />
                    </ReferenceField>
                    <NumberField source="count" label="Количество"/>
                    <NumberField source="sum" label="Сумма"/>
                </Datagrid>
            </ArrayField>
        </SimpleShowLayout>

        <SimpleShowLayout>
            <OrderAddress/>
            <TextField source="orderInfo.deliveryAddress.type" label="Способ доставки"/>
            <TextField source="orderInfo.deliveryAddress.zipCode" label="Индекс"/>
            <TextField source="orderInfo.deliveryAddress.state" label="Область"/>
            <TextField source="orderInfo.deliveryAddress.city" label="Населенный пункт"/>
            <TextField source="orderInfo.deliveryAddress.street" label="Улица"/>
            <TextField source="orderInfo.deliveryAddress.recipient" label="Получатель"/>
            <TextField source="orderInfo.deliveryAddress.phone" label="Телефон"/>
        </SimpleShowLayout>
    </Show>
);